import { User, UserType } from "common/models/user";
import { groupBy, toPairs, sum, isNil } from "lodash";
import { SiteSettings } from "common/utils/holvikaari";

export function isClient(user?: Pick<Partial<User>, "type">) {
  return user?.type === "Client";
}

export const caregiverViewingPatient = (user: User) =>
  user.authenticated_caregiver_id &&
  user.authenticated_caregiver_id.toString() !== user.id;

export function isActive(user: Pick<User, "status">) {
  return user.status === "active" || user.status === "followup";
}

export function isNonMedical(user: { type: UserType }) {
  return user.type === "NonMedicalStaff";
}

export function isAdmin(user: { type: UserType }) {
  return user.type === "Admin";
}

export function isNonMedicalOrAdmin(user: { type: UserType }) {
  return isAdmin(user) || isNonMedical(user);
}

export function isNonMedicalOrAdminOrStudyMonitor(user: { type: UserType }) {
  return isAdmin(user) || isNonMedical(user) || isStudyMonitor(user);
}

export function isMedical(user: Pick<User, "type">) {
  return user.type === "Nurse" || user.type === "Doctor";
}

export function isStudyMonitor(user: { type: UserType }) {
  return user.type === "StudyMonitor";
}

export function isKaikuAdministrator(user: { type: UserType }) {
  return user.type === "KaikuAdministrator";
}

export function isStaff(user: Pick<User, "type">) {
  return isMedical(user) || isKaikuAdministrator(user);
}

export function userCanUpdateMedicalUser(
  loggedInUser: User,
  currentUser: { data: { id: string } },
) {
  return (
    (isMedical(loggedInUser) && currentUser.data.id === loggedInUser.id) ||
    isNonMedicalOrAdmin(loggedInUser) ||
    isKaikuAdministrator(loggedInUser)
  );
}

export function userCanUpdateKaikuAdmin(loggedInUser: User) {
  return (
    isKaikuAdministrator(loggedInUser) || isNonMedicalOrAdmin(loggedInUser)
  );
}
export function initials(user: Pick<User, "full_name" | "name">) {
  const name = user.full_name || user.name;
  return name
    ? name
        .split(/[-\s]/)
        .map(n => n.charAt(0))
        .join("")
    : "";
}

export const hasArticles = (user: Pick<User, "features" | "has_articles">) =>
  user.features && user.has_articles;

export const hasSharedDocuments = (user: User) => user.has_shared_documents;

export const hasLanguageChangingRestriction = (
  user: Pick<User, "type" | "domains">,
): boolean => {
  if (!isClient(user)) {
    return false;
  }

  return (
    user.domains?.filter(d =>
      SiteSettings.restrict_patient_language_change_domains.includes(d),
    ).length !== 0
  );
};

export const missingClientPhoneNumber = (user: Pick<User, "type" | "phone">) =>
  isClient(user) && !user.phone;

export const supportMail = (user?: { support_email?: string }) => {
  return user?.support_email || "";
};

const avg = (arr: number[]) => sum(arr) / arr.length;

// sorts & maps graph API response data:
// API gives an array of input form answers, we need to separate each field to its own series
export const mapGraph = (graph: any) => {
  const groupedFields = toPairs(
    groupBy(graph.fields, f => f.collection_label || f.id),
  ).map(([label, fields]) => ({
    id: label,
    ids: fields.map(f => f.id),
    name: fields[0].name,
    unit: fields[0].unit,
  }));
  return {
    series: groupedFields.map(field => ({
      ...field,
      answers: graph.answers
        .map((answer: any) => {
          const values = field.ids
            .map(f => answer.data_points[f])
            .filter(val => !isNil(val));
          return {
            ...answer,
            values,
            value: values.length ? avg(values) : null,
          };
        })
        .filter((answer: any) => !isNil(answer.value)),
    })),
    name: graph.name,
    has_task: graph.has_task,
    id: graph.id,
  };
};
